import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import { Onboarding } from "./pages/onboarding";
import { Onboarding2 } from "./pages/onboarding2";
import { Welcome } from "./pages/welcome";
import { Home } from "./pages/home";
import { Listen } from "./pages/listen";
import { Header } from './components/header';
import { Install } from './pages/install';
import { Demo } from './pages/demo';
import { Landing } from './pages/landing-page';
import { NewLanding } from './pages/new-landing';
import { NewLanding2 } from './pages/new-landing-2';
import { LandingColors } from './pages/landing-colors';
import { Footer } from './components/footer';


function App() {
  return(
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/landing" element={<Landing />} /> */}
          <Route path="/in-app/onboarding" element={<Onboarding />} />
          <Route path="/in-app/welcome" element={<Welcome />} />
          <Route path="/install" element={<Install />} />
          {/* <Route path="/demo" element={<Demo />} /> */}
          
{/*         
          <Route path="/landing" element={<NewLanding />} />
          <Route path="/landing2" element={<NewLanding2 />} />
          <Route path="/landing3" element={<LandingColors />} /> */}


          {/* <Route path="/i" element={<Onboarding2 />} /> */}
          {/* <Route path="/listen" element={<Listen />} /> */}

          {/* <Route path="/i" element={<Onboarding2 />} /> */}
          
          {/* Adding the link below to incorporate the wrong links shared earlier */}
          <Route path="/v3" element={<Onboarding2 />} />
          
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
      
    </div>
  );
}  
export default App;

